import {
  Button,
  CircularProgress,
  makeStyles,
  Tooltip,
} from '@material-ui/core'
import {
  GET_LIST,
  showNotification as showNotificationAction,
  SortPayload,
} from 'ra-core'
import { useCallback, useState } from 'react'
import { ListProps, useGetIdentity } from 'react-admin'
import { connect } from 'react-redux'
import { getToken } from '../authProvider'
import { FilterCompositions } from '../dataProvider/buildFilter'
import { getDataResolver } from '../dataProvider/buildQuery'
import { GetListParams } from '../dataProvider/introspections/getList'
import { useSchemaTraverser } from '../hooks/useSchemaTraverser'
import { BLACKLIST_TABLE_EXPORT_BY_ROLE } from '../lib/config'
import { smplTexts } from './CustomTextMappings'
import { filterPresetRessourceMapping } from './filterBuilder/filterPresetDefinitions'

const useStyles = makeStyles(() => ({
  actionButton: {
    padding: '0.5rem 1rem',
    background: '#cdcdcd',
  },
  loadingSpinner: {
    marginLeft: 8,
  },
}))

const iframeId = 'exportDownloadIframe' + Math.random()

function getQuerySource(query: any) {
  // result from graphql-tag does not have a type...
  return query.loc.source.body
}

async function downloadCSV(
  resource: string,
  queryString: string,
  showNotification: (
    message: string,
    type: 'warning' | 'info' | 'error'
  ) => void,
  filter?: string
) {
  return await fetch(
    process.env.PUBLIC_URL + `/api/export/${resource}?data=${queryString}`,
    {
      method: 'GET',
      headers: {
        Authorization: getToken(),
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        showNotification('Error while exporting data', 'error')
      } else {
        return response.blob()
      }
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = `${resource}.csv`
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      console.error('Error while exporting data', error)
      showNotification(`Export failed`, 'error')
    })
}

type ExportButtonType = ListProps & {
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'default' | 'inherit' | 'primary' | 'secondary'
  startIcon?: JSX.Element
  buttonText?: string
  filterValues?: { [key: string]: any }
  currentSort?: SortPayload
}

function _ExportButton(
  props: ExportButtonType & {
    showNotification: typeof showNotificationAction
  }
) {
  const {
    filterValues,
    currentSort,
    resource,
    size,
    variant,
    color,
    buttonText,
    startIcon,
    showNotification,
  } = props

  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const tableWithExportBlackList: {
    tableName: string
    blackListedRole: string[]
  }[] = JSON.parse(BLACKLIST_TABLE_EXPORT_BY_ROLE)

  const isTableInExportBlacklist = tableWithExportBlackList.find(
    (bl) => bl.tableName === resource
  )

  const { identity, loaded: loadedIdentity } = useGetIdentity()

  const schemaTraverser = useSchemaTraverser()
  if (!schemaTraverser) {
    throw new Error('no schema traverser')
  }
  if (!resource) {
    throw new Error('resource unknown')
  }
  const startExport = useCallback(
    async (
      showNotification: (
        message: string,
        type: 'warning' | 'info' | 'error'
      ) => void
    ) => {
      setLoading(true)
      try {
        // Check if there are match filters for the tagged users
        let resourceWithFilter: FilterCompositions | null = null
        if (resource === 'IdentificationDatum') {
          // Get tagged user's filter if given
          const filterValue = props?.filterValues?.__preset?.matches?.[0] ?? ''
          if (typeof filterValue === 'string' && filterValue !== '') {
            const matchAmlTag = filterPresetRessourceMapping[resource].find(
              (amlTag) => amlTag.internal === filterValue
            )
            resourceWithFilter = matchAmlTag.filter
          }
        }

        const params: GetListParams = {
          sort: currentSort ? currentSort : null,
          pagination: null,
          filter: resourceWithFilter ?? filterValues,
        }

        const resolver = getDataResolver()
        const res = await resolver(GET_LIST, resource, params)
        if (!res) {
          throw new Error('invalid request type, not allowed by data resolver')
        }

        const query = getQuerySource(res.query)
        const { variables } = res

        const queryString = JSON.stringify({
          query: query.replace(/ +/g, ' ').trim(), // remove unnecessary whitespace
          variables,
          authorization: getToken(),
        })

        // send with get request, variables need to be encoded to prevent errors on backend
        showNotification('Starting export...', 'info')
        await downloadCSV(
          resource,
          encodeURIComponent(queryString),
          showNotification
        )
      } finally {
        setLoading(false)
      }
    },
    [filterValues, currentSort, resource]
  )

  if (!loadedIdentity && !!isTableInExportBlacklist) {
    return null
  }

  if (
    isTableInExportBlacklist &&
    isTableInExportBlacklist.blackListedRole.includes(identity.role)
  ) {
    return null
  }

  return (
    <Tooltip title={smplTexts.datagrid.export}>
      <Button
        size={size || 'medium'}
        color={color || 'default'}
        variant={variant || 'text'}
        startIcon={loading ? <CircularProgress size="1rem" /> : startIcon}
        onClick={() => {
          startExport(showNotification)
        }}
        className={classes.actionButton}
        disabled={loading}
      >
        {buttonText || 'Export'}
      </Button>
    </Tooltip>
  )
}

export const ExportButton = connect(null, {
  showNotification: showNotificationAction,
})(_ExportButton)
