import { Button } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

import Eyecon from '@material-ui/icons/RemoveRedEye'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import { motion } from 'framer-motion'
import startCase from 'lodash/startCase'
import pluralize from 'pluralize'
import React, { Suspense, useCallback, useMemo, useRef, useState } from 'react'
import {
  DateField,
  FunctionField,
  List,
  ListProps,
  Pagination,
  Record,
  Responsive,
  ShowButton,
  SimpleList,
  useRedirect,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { GraphQLFilter } from '../dataProvider/buildFilter'
import { getSchemaTraverser } from '../dataProvider/buildQuery'
import CustomizableDatagrid from '../datagrid/CustomizeableDatagrid'
import LocalStorage, { LIST_STORAGE_KEY } from '../datagrid/LocalStorage'
import { useDocumentTitle } from '../hooks/useDocumentTitle'
import { useURLSearchParams } from '../hooks/useURLSearchParams'
import { SmplFooter } from '../layout/SmplFooter'
import { smplColors } from '../layout/themes'
import {
  DEFAULT_TRANSCODING_QUEUE_ARN,
  RESERVED_TRANSCODING_QUEUE_ARN,
  isArdCustomer,
} from '../lib/config'
import { filterNotEmpty } from '../lib/filterNonEmpty'
import { BatchEditModal } from './BatchEditModal'
import { BulkResourceActions } from './BulkResourceActions'
import {
  FAKE_PRESET_FILTER_FIELD,
  FAKE_PRESET_FILTER_OPERATION,
} from './CustomFilter'
import { GenericFilter } from './GenericFilter'
import { ResourceActions } from './ResourceActions'
import { getUsableDataSource } from './customFields/FallbackSourceField'
import {
  FilterPreset,
  filterPresetRessourceMapping,
} from './filterBuilder/filterPresetDefinitions'
import { mergeFilters } from './filterBuilder/normalizeFilter'
import {
  TranscodingJobAssetTitle,
  TranscodingJobPrioritySelectionDialog,
  TranscodingJobStatusIndicator,
} from './node-actions/transcoding/transcodingJobCustomDataListFields'
import { renderField } from './renderField'
import { ListResourceSetting } from './settings/ListResourceSetting'
import { TabLayoutSettings } from './settings/TabLayoutSetting'
import { NONE, TypeDisplaySetting } from './settings/TypeDisplaySetting'

export const fallbackSources = [
  'name',
  'label',
  'displayName',
  'username',
  'email',
  'title',
  'id',
]

const useStyles = makeStyles(() => ({
  datagrid: {
    '& tr:nth-child(odd)': { background: '#ffffff80' },
  },
  datagridSideOpened: {
    '& > div > div:last-child': {
      overflow: 'auto',
      overflowY: 'hidden',
      // with this, left and right of the scrollable area are is visually 'identical'
      maxWidth: 'calc(100vw - 290px)',
    },
  },
  datagridSideClosed: {
    '& > div > div:last-child': {
      overflow: 'auto',
      overflowY: 'hidden',
      // with this, left and right of the scrollable area are is visually 'identical'
      maxWidth: 'calc(100vw - 105px)',
    },
  },
  datagridInModal: {
    '& > div > div:last-child': {
      overflow: 'auto',
      // with this, left and right of the scrollable area are is visually 'identical'
      maxWidth: '100vw',
    },
  },
  header: {
    textAlign: 'center',
    paddingTop: '1em',
    marginBottom: 'revert',
  },
  withTopToolbar: {
    '& > div:first-of-type': {
      marginBottom: 60,
    },
  },

  progressBarCompleted: {
    width: '200px',
    height: '10px',
    borderRadius: '3px',
    '& div': {
      background: `linear-gradient(0deg, ${smplColors.success.main}aa 20%, ${smplColors.success.main}ff 50%, ${smplColors.success.main}aa 80%)`,
    },
  },
  progressBarOff: {
    width: '200px',
    height: '10px',
    borderRadius: '3px',
    background: 'gray',
    '& div': {
      background: 'gray',
    },
  },
  progressBarError: {
    width: '200px',
    height: '10px',
    borderRadius: '3px',
    background: '#882525',
    '& div': {
      background: `linear-gradient(0deg, #ff0000aa 20%, #ff0000ff 50%, #ff0000aa 80%)`,
    },
  },
  progressBar: {
    width: '200px',
    height: '10px',
    borderRadius: '3px',
    background: `${smplColors.primary.main}`,
    '& div': {
      background: `linear-gradient(0deg, ${smplColors.secondary.main}aa 20%, ${smplColors.secondary.main}ff 50%, ${smplColors.secondary.main}aa 80%)`,
      borderRadius: '2px',
      overflow: 'hidden',
      '&::after': {
        position: 'absolute',
        content: '""',
        background: '#ffffffcc',
        height: '50px',
        width: '80px',
        left: 'calc(50% - 40px)',
        filter: 'blur(10px)',
        animation: '$barflash 3s ease-out infinite',
      },
    },
  },
  '@keyframes barflash': {
    '0%': {
      opacity: 0.25,
      left: 'calc(0% - 100px)',
    },
    '100%': {
      opacity: 1,
      left: 'calc(100% + 20px)',
    },
  },
}))

export const PostPagination = (props: any) => {
  return (
    <Pagination rowsPerPageOptions={[5, 10, 15, 25, 50, 75, 100]} {...props} />
  )
}

export const DEFAULT_SORT = {
  field: 'createdDate',
  order: 'DESC',
}

const ImgDropZone = React.lazy(async () => {
  return import('./customFields/DropZone').then(({ DropZone }) => {
    return {
      default: DropZone,
    }
  })
})

type DefaultListProps = React.ComponentProps<typeof List>

const sourcesWithoutId = fallbackSources.filter((key) => key !== 'id')

function getNicePrimaryTextLabel(record: Record) {
  const source = getUsableDataSource(sourcesWithoutId, record)
  if (source && source in record) {
    return record[source]
  }
}

function getPrimaryText(record: Record) {
  const primary = getNicePrimaryTextLabel(record)
  return primary || (record ? record.id : '')
}

function getSecondaryText(record: Record) {
  const primary = getNicePrimaryTextLabel(record)
  return (
    <span style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
      {primary ? <span style={{ marginRight: 5 }}>{record.id}</span> : null}
      <span style={{ marginRight: 5 }}>Created:</span>
      <DateField source="createdDate" record={record} />
    </span>
  )
}

type Props = ListProps & {
  // set Type for Custom Component example:
  ListComponent?: React.ComponentType<DefaultListProps>
  onOpenCreateModal?: () => void
  location?: Object
  calledFromModal?: boolean
  setFunction?: Function
  setFunctionEntry?: Function
  setFunctionEntries?: React.Dispatch<React.SetStateAction<Record[] | null>>
  openedModal?: Function
  hasCreateInModal?: boolean
  modalFieldName?: string
}

export const GenericDataList = React.memo(function GenericDataListImpl(
  props: Props
) {
  const {
    ListComponent = List, // if ListComponent === null ? List : props.ListCompoent
    onOpenCreateModal,
    calledFromModal = false,
    setFunction,
    // TODO: find all place that use setFunctionEntry and replace it with setFunctionEntries
    setFunctionEntry,
    setFunctionEntries,
    openedModal,
    hasCreateInModal = false,
    modalFieldName,
    ...otherProps
  } = props

  const { resource, location, history } = otherProps
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
  const classes = useStyles()
  const dataGridRef = useRef<CustomizableDatagrid>(null)
  const traverser = getSchemaTraverser()

  // this need to be set inside he List we don't have access to it here
  const [hasSelectedIds, setHasSelectedIds] = useState<Boolean>(false)

  // have to do it like this because data from useListContext only contains list item from current listpage (10, 25 etc. items, not all)
  const [selectedData, setSelectedData] = useState<Record[]>([])

  // state for opening batch edit modal
  const [batchEditOpen, setBatchEditOpen] = useState(false)

  const resourceTraverser = useMemo(() => {
    if (!traverser || !resource) {
      return null
    }
    const r = traverser.getResourceTraverserByName(resource)
    if (!r) {
      throw new Error('Resource not found! ' + resource)
    }
    return r
  }, [traverser, resource])

  // to know what fields to not show in the list view, e.g. if group is NONE
  const typeDisplaySettings = TypeDisplaySetting[resource]?.show?.fields

  const renderedFields = useMemo(() => {
    return (
      resourceTraverser &&
      resourceTraverser.fields
        .map((field) => {
          const rendered = renderField(field, 'list')
          if (rendered) {
            return {
              field,
              rendered,
            }
          } else {
            return null
          }
        })
        .filter(filterNotEmpty)
    )
  }, [resourceTraverser])

  // INFO: we use this to force rerender this page after user changes the List Display Setting
  // only use this in combination with switching List View and nothing else!!!
  const [, updateState] = useState()
  // @ts-ignore
  const forceUpdate = useCallback(() => updateState({}), [])

  // return custom path/url if necessary
  const postRowClick = useCallback(
    (id: string, basePath: string, record: object) => {
      return 'show'
    },
    []
  )

  const postModalRowClick = useCallback(
    (id: string, record: Record) => {
      if (typeof setFunction !== 'undefined' && openedModal !== undefined) {
        setFunction(id) // return the id back to the edit/create page
        openedModal(false) // close the modal upon selection
      } else if (
        typeof setFunctionEntry !== 'undefined' &&
        openedModal !== undefined
      ) {
        setFunctionEntry(record) // return the id back to the edit/create page
        openedModal(false) // close the modal upon selection
      } else if (
        typeof setFunctionEntries !== 'undefined' &&
        openedModal !== undefined
      ) {
        setFunctionEntries([record]) // return only the record of the row clicked
        openedModal(false) // close the modal upon selection
      } else if (typeof setFunction !== 'undefined') {
        setFunction(id)
      }
    },
    [openedModal, setFunction, setFunctionEntry]
  )

  const { sort = DEFAULT_SORT } = props

  const postImgUpload = (id: string) => {
    if (typeof setFunction !== 'undefined' && openedModal !== undefined) {
      setFunction(id) // return the id back to the edit/create page
      openedModal(false) // close the modal upon selection
    }
  }

  const onRowClick = useCallback(
    (id: string, basePath: string, record: Record) => {
      if (calledFromModal === false) {
        return postRowClick(id, basePath, record) // return a value, e.g. return 'show'
      } else {
        postModalRowClick(id, record) // pass the id to some state and close the modal
      }
    },
    []
  )

  const params = useURLSearchParams()
  const filterParams = JSON.parse(params.get('filter') || '{}')
  const filterPresetNames: string[] =
    filterParams[FAKE_PRESET_FILTER_FIELD]?.[FAKE_PRESET_FILTER_OPERATION] || []

  const forcedFilter = {
    // and: [{ comment: { isNull: true } as StringFilter }],
  } as GraphQLFilter

  const possibleFilterPresets = resource
    ? filterPresetRessourceMapping[resource]
    : undefined
  const filterPresets = useMemo(
    () =>
      possibleFilterPresets?.filter((preset) => {
        return filterPresetNames.includes(preset.internal)
      }) || [],
    [possibleFilterPresets, JSON.stringify(filterPresetNames || '')]
  )

  const appliedFilters = useMemo(() => {
    let filters = { ...filterParams }
    delete filters[FAKE_PRESET_FILTER_FIELD]

    for (const filter of filterPresets) {
      filters = mergeFilters(filters, filter.filter, resourceTraverser!)
    }
    return mergeFilters(filters, forcedFilter, resourceTraverser!)
  }, [filterParams, forcedFilter, filterPresets])

  const selectFilterPreset = useCallback(
    (preset: FilterPreset | null) => {
      const params = new URLSearchParams(location?.search || '')
      const copy = { ...filterParams }
      if (preset == null) {
        delete copy[FAKE_PRESET_FILTER_FIELD]
      } else {
        copy[FAKE_PRESET_FILTER_FIELD] = {
          [FAKE_PRESET_FILTER_OPERATION]: [preset.internal],
        }
      }
      params.set('filter', JSON.stringify(copy))

      history?.replace({
        search: '?' + params.toString(),
      })
    },
    [history, location]
  )
  const title = pluralize.plural(resource ?? '')

  useDocumentTitle({
    title: startCase(title),
  })

  if (!traverser || !resource || !renderedFields) {
    return null
  }

  // this is for tree view in modal
  // TODO: remove this -> no tree view in modal
  if (!modalFieldName && resource) {
    const storage = LocalStorage
    const savedListSetting: {
      name: string
    } = storage.get(LIST_STORAGE_KEY, resource)

    if (
      savedListSetting &&
      savedListSetting.name !== 'default' &&
      ListResourceSetting[resource]
    ) {
      const ListComp = ListResourceSetting[resource][savedListSetting.name].comp
      if (ListComp) {
        return (
          <ListComp
            {...props}
            forceUpdate={forceUpdate}
            onShowButtonClick={
              calledFromModal && openedModal ? postModalRowClick : undefined
            }
            showButtonLabel={calledFromModal && openedModal ? 'select' : 'show'}
          />
        )
      }
    }
  }

  return (
    <>
      <motion.div
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: 'backOut', duration: 1 }}
      >
        {/* HEADER IF RENDER INSIDE MODAL FOR SELECTION */}
        {/* {setFunction || setFunctionEntry || setFunctionEntries ? (
        modalFieldName ? (
          <Typography variant="h5" gutterBottom className={classes.header}>
            Select a {resource === 'CmsSery' ? 'CmsSeries' : resource} element
            for {modalFieldName}
          </Typography>
        ) : (
          <Typography variant="h5" gutterBottom className={classes.header}>
            Select a {resource === 'CmsSery' ? 'CmsSeries' : resource} element
          </Typography>
        )
      ) : (
        <TypeDescription resource={resource} />
      )} */}
        {/* HEADER IF RENDER INSIDE MODAL FOR SELECTION END */}
        {/* IMG DROPZONE FOR CMSIMAGE */}
        <Suspense
          fallback={
            <div
              style={{
                height: 190,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              Loading Image Upload Zone...
            </div>
          }
        >
          {resource === 'CmsImage' ? (
            <motion.div
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 2, ease: 'anticipate' }}
              style={{ marginTop: 22 }}
            >
              <ImgDropZone
                returnIdToModal={openedModal ? postImgUpload : null}
              />
            </motion.div>
          ) : null}
        </Suspense>
        {/* IMG DROPZONE FOR CMSIMAGE END */}
        {/* DATAGRID */}
        <ListComponent
          {...otherProps}
          pagination={<PostPagination />}
          sort={sort}
          title={title}
          filter={appliedFilters}
          filters={
            <GenericFilter
              forcedFilters={forcedFilter}
              activeFilterPresets={filterPresets?.filter((preset) => {
                return !(preset.hidden ? preset.hidden : false)
              })}
              possibleFilterPresets={possibleFilterPresets?.filter((preset) => {
                return !(preset.hidden ? preset.hidden : false)
              })}
              selectFilterPreset={selectFilterPreset}
            />
          }
          exporter={false}
          actions={
            <ResourceActions
              dataGridRef={dataGridRef}
              hasCreateInModal={hasCreateInModal}
              onOpenCreateModal={onOpenCreateModal}
              forceUpdate={forceUpdate}
              setHasSelectedIds={setHasSelectedIds}
            />
          }
          className={
            calledFromModal
              ? `${classes.datagridInModal} ${
                  hasSelectedIds ? classes.withTopToolbar : undefined
                }`
              : open
              ? `${classes.datagridSideOpened} ${
                  hasSelectedIds ? classes.withTopToolbar : undefined
                }`
              : classes.datagridSideClosed
          }
          bulkActionButtons={
            <BulkResourceActions
              setFunctionEntries={setFunctionEntries}
              openedModal={openedModal}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              setBatchEditOpen={setBatchEditOpen}
            />
          }
        >
          <Responsive
            small={
              calledFromModal ? undefined : ( // show Datagrid instead
                // this don't have rowClick, via onClick we don't get information about the row
                <SimpleList
                  primaryText={getPrimaryText}
                  secondaryText={getSecondaryText}
                  leftIcon={() => <Eyecon />}
                  linkType="show"
                />
              )
            }
            medium={
              <CustomizableDatagrid
                ref={dataGridRef}
                className={classes.datagrid}
                rowClick={onRowClick}
                defaultColumns={renderedFields
                  .filter(
                    ({
                      field: { isHiddenByDefaultInList, name, resource },
                    }) => {
                      if (
                        name === 'lastModifiedDate' &&
                        resource.name === 'TranscodingJob'
                      ) {
                        return true
                      }

                      if (isHiddenByDefaultInList) {
                        return false
                      }

                      if (
                        typeDisplaySettings &&
                        typeDisplaySettings[name] &&
                        typeDisplaySettings[name].group === NONE
                      ) {
                        return false
                      }
                      return true
                    }
                  )
                  .map(({ field: { name } }) => name)}
              >
                {/* show button */}
                {
                  resource !== 'TranscodingJob' ? (
                    <ShowButton />
                  ) : (
                    <div key="empty"></div>
                  ) // can't return undefined or it throws error
                }
                {/* custom review button */}
                {TabLayoutSettings[resource] &&
                Object.keys(TabLayoutSettings[resource].tabs).includes(
                  'Review'
                ) ? (
                  <CustomShowButton toTabName="Review" />
                ) : (
                  <div key="empty"></div> // can't return undefined or null, otherwise it throws error
                )}

                {/* CUSTOM TRANSCODING JOB COLUMNS - */}
                {/** TODO: To ensure that each custom field for the TranscodingJob is rendered in its own column, we need to ensure that each FunctionField is
                 * placed separately within the Datagrid and not nested under a single conditional check. Therefore, we will repeat the resource === 'TranscodingJob' check for each FunctionField. */}
                {resource === 'TranscodingJob' &&
                  isArdCustomer &&
                  DEFAULT_TRANSCODING_QUEUE_ARN &&
                  RESERVED_TRANSCODING_QUEUE_ARN && (
                    <FunctionField
                      label="Change Priority"
                      style={{ paddingLeft: 10 }}
                      render={(record) => (
                        // TODO: check queue pricing plan status before providing the feature, since the env vars might be set up but the queues could be inactive/expired
                        <TranscodingJobPrioritySelectionDialog
                          record={record}
                        />
                      )}
                    />
                  )}

                {resource === 'TranscodingJob' && (
                  <FunctionField
                    label="Status Indicator"
                    style={{ paddingLeft: 15 }}
                    render={(record) => (
                      <TranscodingJobStatusIndicator record={record} />
                    )}
                  />
                )}

                {resource === 'TranscodingJob' && (
                  <FunctionField
                    label="Progressbar"
                    render={(record) => (
                      <LinearProgress
                        className={
                          record.status === 'IN_PROGRESS'
                            ? classes.progressBar
                            : record.status === 'COMPLETED'
                            ? classes.progressBarCompleted
                            : record.status === 'READY_FOR_QA'
                            ? classes.progressBarCompleted
                            : record.status === 'ERROR'
                            ? classes.progressBarError
                            : classes.progressBarOff
                        }
                        value={record.progress}
                        variant="determinate"
                        color="secondary"
                      />
                    )}
                  />
                )}

                {resource === 'TranscodingJob' && (
                  <FunctionField
                    label="Asset Title"
                    render={(record) => (
                      <TranscodingJobAssetTitle record={record} />
                    )}
                  />
                )}
                {/* ALL OTHER FIELDS */}
                {renderedFields.map(({ rendered }) => {
                  return rendered
                })}
              </CustomizableDatagrid>
            }
          />
        </ListComponent>
        {/* DATAGRID END */}
        {/* BATCH EDIT */}
        <BatchEditModal
          open={batchEditOpen}
          onClose={() => {
            setBatchEditOpen(false)
          }}
          resource={resource}
          selectedData={selectedData}
        />
        {/* BATCH EDIT END */}
        <SmplFooter />
      </motion.div>
    </>
  )
})

const CustomShowButton = (props: any) => {
  const { resource, record, toTabName } = props
  const redirect = useRedirect()

  // open new Tab on click on Show
  return (
    <Button
      color="primary"
      size="small"
      onClick={(e) => {
        redirect(() => {
          return `/${resource}/${record.id}/show/${toTabName}`
        })
        e.stopPropagation() // otherwise it will trigger rowclick to show
      }}
    >
      <SkipNextIcon /> {toTabName}
    </Button>
  )
}
